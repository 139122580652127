import React from 'react'
import './about.css'
import ME from '../../assets/me_about.png'
import { AiOutlineBook } from 'react-icons/ai'
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { IoMdContacts } from "react-icons/io";
const about = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
          <img src={ME} alt="ME" />
          </div>
        </div>
        <div className="about__content">
          <div className="about_cards">
            <article className="about_card">
            <IoMdContacts className='about_icon'/>
              <h5>Client</h5>
              <small>19+ Clients
               </small>
            </article>

            <article className="about_card">
              <AiOutlineBook className='about_icon'/>
              <h5>Experience</h5>
              <small>2.5+ years</small>
            </article>

            <article className="about_card">
            <AiOutlineAppstoreAdd  className='about_icon'/>
              <h5>Project</h5>
              <small>46+ Completed</small>
            </article> 
          </div>
          <p>I am Mohd Faizan, a freelance MERN stack developer with 2.5+ years of experience. I specialize in creating dynamic, responsive websites using MongoDB, Express.js, React, and Node.js. Additionally, I offer WordPress, Odoo, and Shopify website development. I prioritize clear communication and collaboration to ensure your vision is brought to life effectively. Let's work together to build a standout web presence for your business.</p>
          <a href='#contact' className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default about