import React from 'react'
import './service.css'
import {BiCheck} from 'react-icons/bi'


const service = () => {
  return (
    <section id='service'>
      
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container service_container">
        <article className='service'>
          <div className="service_head">
            <h3>UI/UX</h3>
          </div>
          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>User Interface (UI) Design</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>User Experience (UX) Research:</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Logo Design </p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Animation Prototype</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Accessibility and Inclusivity Design:</p>
            </li>
          </ul>
        </article>
        {/* END OF UI UX */}

        <article className='service'>
          <div className="service_head">
            <h3>Web Development</h3>
          </div>
          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Shopify Website</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>WorldPress Website</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Back-end Development</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>E-commerce Website Development</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Web Application Development</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Oodo Website</p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}

        <article className='service'>
          <div className="service_head">
            <h3>Digital Marketing</h3>
          </div>
          <ul className='service_list'>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Search Engine Optimization (SEO)</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Social Media Marketing</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Meta Ads</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Social Media Management</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Video Marketing</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Email Marketing</p>
            </li>
          </ul>
        </article>
        {/* END OF UI UX */}
  
      </div>
    </section>
    
  )
}

export default service