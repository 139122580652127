import './portfolio.css';
import React, { useRef } from 'react';

// Import video files
import VIDEO1 from '../../assets/Video1.mp4';
import VIDEO2 from '../../assets/Video2.mp4';
import VIDEO3 from '../../assets/Video3.mp4';
import VIDEO4 from '../../assets/Video4.mp4';
import VIDEO5 from '../../assets/Video5.mp4';
import VIDEO6 from '../../assets/Video6.mp4';

const Portfolio = () => {
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  const videoRef5 = useRef(null);
  const videoRef6 = useRef(null);

  const handleMouseOver = (videoRef) => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error('Error playing video:', error);
      });
    }
  };

  const handleMouseOut = (videoRef) => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <section id='portfolio'>
      <div className="title-container">
        <h2 className="title-animation">Do You Want Your Website Looks Like</h2>
      </div>
      <div className='container portfolio_container'>
        <article className='portfolio_item'>
          <div
            className="portfolio_item-image"
            onMouseOver={() => handleMouseOver(videoRef1)}
            onMouseOut={() => handleMouseOut(videoRef1)}
          >
            <video ref={videoRef1} className="video" src={VIDEO1} muted />
          </div>
        </article>
        <article className='portfolio_item'>
          <div
            className="portfolio_item-image"
            onMouseOver={() => handleMouseOver(videoRef2)}
            onMouseOut={() => handleMouseOut(videoRef2)}
          >
            <video ref={videoRef2} className="video" src={VIDEO2} muted />
          </div>
        </article>
        <article className='portfolio_item'>
          <div
            className="portfolio_item-image"
            onMouseOver={() => handleMouseOver(videoRef3)}
            onMouseOut={() => handleMouseOut(videoRef3)}
          >
            <video ref={videoRef3} className="video" src={VIDEO3} muted />
          </div>
        </article>
        <article className='portfolio_item'>
          <div
            className="portfolio_item-image"
            onMouseOver={() => handleMouseOver(videoRef4)}
            onMouseOut={() => handleMouseOut(videoRef4)}
          >
            <video ref={videoRef4} className="video" src={VIDEO4} muted />
          </div>
        </article>
        <article className='portfolio_item'>
          <div
            className="portfolio_item-image"
            onMouseOver={() => handleMouseOver(videoRef5)}
            onMouseOut={() => handleMouseOut(videoRef5)}
          >
            <video ref={videoRef5} className="video" src={VIDEO5} muted />
          </div>
        </article>
        <article className='portfolio_item'>
          <div
            className="portfolio_item-image"
            onMouseOver={() => handleMouseOver(videoRef6)}
            onMouseOut={() => handleMouseOut(videoRef6)}
          >
            <video ref={videoRef6} className="video" src={VIDEO6} muted />
          </div>
        </article>
      </div>
    </section>
  );
};

export default Portfolio;
