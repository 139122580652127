import React, { useEffect } from 'react';
import './header.css';
import CTA from './CTA'; 
import ME from '../../assets/me.png';
import Headersocial from './headersocial';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Header = () => {
  useEffect(() => {
    gsap.fromTo(".me img", 
      { 
        y: 100, 
        scale: 1.5,  // Initial larger size
        opacity: 0 
      }, 
      { 
        y: 0, 
        scale: 1,   // Final original size
        opacity: 1, 
        duration: 1.5, 
        ease: "power3.out",
        scrollTrigger: {
          trigger: ".me",
          start: "top 80%", // Adjust based on when you want the animation to start
          end: "bottom 20%",
          scrub: true
        }
      }
    );
  }, []);

  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Mohd Faizan</h1>
        <h5 className="text-light">MERN Stack Developer</h5> 
        <CTA/>
        <div>
          <Headersocial/>
        </div>
        <div className="me">
          <img src={ME} alt="ME" />
        </div>
        <a href="#contact" className='scroll_down'>Scroll Down</a>
      </div>
    </header>
  );
}

export default Header;
